import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const TriDReklama = () => {
  return (
    <>
      <Helmet>
        <title>3D реклама в Москве создание анимации от студии Elandic</title>
        <meta name="description" content="3D реклама в Москве цена создания анимации для бизнеса от видеопродакшена Elandic. Заказать профессиональное создание 3D анимационных роликов для бизнеса. " />
        <meta name="keywords" content="3d реклама, москва, анимация для бизнеса, создание" />
      </Helmet>
      <MainPage>
        <SeoText title='3D реклама'>
        <p>В эпоху цифровых технологий 3D реклама в Москве открывает новые возможности для продвижения бизнеса. Видеопродакшн Elandic представляет собой команду профессионалов, способных воплотить любую вашу идею в захватывающую 3D анимацию, повысив узнаваемость вашего бренда и привлекательность вашего продукта для потенциальных клиентов.</p>

<p>Создание 3D рекламы требует не только глубоких технических знаний, но и творческого подхода. Наши специалисты сочетают в себе эти качества, предоставляя услуги по созданию 3D анимации для бизнеса на высочайшем уровне. Мы работаем с использованием передового программного обеспечения и технологий для того, чтобы каждый проект был уникален и максимально эффективен в рекламных целях.</p>

<p>Студия Elandic предлагает полный спектр услуг в области 3D рекламы – от разработки концепции и сценария до финальной реализации проекта. Наши работы отличаются не только неповторимостью и техничностью, но и ориентацией на результат, который выражается в увеличении продаж и узнаваемости бренда.</p>

<p>Выбирая студию Elandic для создания 3D рекламы в Москве, вы получаете не просто видео, а мощный инструмент маркетинга, способный значительно улучшить позиции вашего бизнеса на рынке. Мы готовы поделиться с вами нашими знаниями и опытом, чтобы помочь вашему бренду стать более заметным и привлекательным для клиентов. Обращайтесь в Elandic – и ваша реклама засверкает новыми красками в мире 3D анимации!</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(TriDReklama);
